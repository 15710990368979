<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.create_key") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @stateChange="watchFields"
        @submitForm="createKey"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.key.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const formElements = ref(null);

    const setFormElements = (state = null) => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "stamp",
          label: "stamp",
          rules: ["isRequired"],
          type: "text",
          value: "",
          variable: "stamp",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "estate",
          label: "estate_name",
          optionLabel: ["name"],
          options: estates.value,
          rules: ["isRequired"],
          value: null,
          variable: "estateId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "building",
          label: "building",
          optionLabel: ["name", "address", "postalCode", "city"],
          options:
            estates.value.find((estate) => estate.id == state?.estateId?.value)
              ?.buildings || [],
          rules: ["isRequired"],
          value: state?.buildingId?.value,
          variable: "buildingId",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const createKey = (formData) => {
      const chosenEstate = estates.value.find(
        (estate) => estate.id == formData.estateId
      );
      const data = {
        buildingId: formData.buildingId,
        companyId: chosenEstate.companyId,
        estateId: formData.estateId,
        stamp: formData.stamp,
        loaned: false,
      };
      store.dispatch("key/createKey", data);
    };

    const watchFields = (state) => {
      if (state.estateId.value) {
        setFormElements(state);
      }
    };

    onMounted(() => {
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", user.value.companyId);
      }
      if (company.value.id && estates.value.length) {
        setFormElements();
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      createKey,
      estates,
      formElements,
      t,
      watchFields,
    };
  },
};
</script>
